/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import "../custom-bootstrap.scss"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <main>{children}</main>
      <footer className="text-center">
        <p className="p-3">
          Made with{" "}
          <span role="img" aria-label="love">
            ❤️
          </span>{" "}
          by Aukje Reurink via{" "}
          <a href="https://parasschute.nl/2019/06/26/nieuws/">parasschute.nl</a>
        </p>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
